.desktop-header {
  padding: 11px 30px;


  .logo {
    img {
      width: 170px;
    }
  }

  .navbar {
    padding: 0%;

    &__nav-link {
      color: var(--color-white);
      padding-left: .9765625vw;
      padding-right: .9765625vw;
      font-size: var(--font-size-24);
      text-decoration: none;
      font-weight: var(--font-weight-600);
      cursor: pointer;

      &:hover {
        color: var(--color-light-blue);
      }

    }

    &__nav-link.active {
      color: var(--color-light-blue);
    }

    &__sbi-card-banner {
      object-fit: cover;
      max-width: 36%;
      height: auto;
      // position: absolute;
      // left: 50%;
      // transform: translateX(-50%);
      img{
        width: 100%;
      }
    }
    &_btn-section {
      align-items: center;
      height: 57px;
    }
    &_language-select {
      border-radius: 10px;
      padding: 8px 3px;
      margin-right: 1px;
    }
    &__techcompare-btn {
      right: 0px;
      background-color: var(--color-light-blue) !important;
      margin-left: 4px !important;
      font-size: var(--font-size-normal) !important;
      padding: 8px 18px 8px 18px !important;
      text-transform: capitalize !important;
      min-width: unset !important;

      img {
        height: 17px;
        margin-right: 6px;
      }
    }

    &__techcompare-btn-content {
      width: max-content;
    }
  }
}

.header {
  background-color: var(--color-light-black);
  position: sticky;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 99;
  margin: 0px auto;
}

.navbar-expand-lg {
  flex-wrap: nowrap;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .desktop-header {
    display: none;
  }

  .mobile-header {
    display: block;
    padding: 6px 12px;

    .logo {
      padding-right: 0%;

      img {
        width: 120px;
      }
    }

  }

  .navbar {
    &__menu-wrapper {
      color: var(--color-white);
      font-size: var(--font-size-24);
      display: flex;
      justify-content: center;

      svg {
        font-size: var(--font-size-20) !important;
      }
    }

    &__menu-drawer-container {
      position: absolute;
      transform: translate(-1%);
      height: 100vh;
      z-index: 13;
      left: 0;
      top: 100%;
      width: 60%;
      padding: 0;
      background-color: var(--color-light-black);
      overflow-x: hidden;
    }
  }

}

@media only screen and (max-width: 767px) {
  .navbar {
    .navbar-nav {
      display: inline-block;
      min-width: 100%;
      text-align: center;
    }
  }
}

@media only screen and (min-width: 768px) {
  .desktop-header {
    display: block;
  }

  .mobile-header {
    display: none;
  }
}

@media only screen and (max-width: 1050px) {
  .desktop-header {
    padding: 9px 18px;


    .logo {
      padding-right: 2%;

      img {
        width: 125px;
      }
    }

    .navbar {

      &-nav {
        flex-direction: row;
        font-size: var(--font-size-10);
      }

      &__sbi-card-banner {
        max-width: 36%;
        img{
          width: 100%;
        }
      }

      &__techcompare-btn {
        font-size: var(--font-size-10) !important;

        img {
          height: 15px;
        }
      }
    }
  }
}

@media screen and (min-width: 1051px) and (max-width: 1200px) {
  .desktop-header {
    .navbar {
      &__sbi-card-banner {
        max-width: 36%;
        img{
          width: 100%;
        }
      }
    }
  }
}