.material-thickness-Comparison {
  max-width: 100%;
  background-color: var(--color-white);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  overflow-y: auto;
  &__card {
    overflow: hidden;
    padding: 0px 5px 10px 0px;
    text-align: center;
    margin-bottom: 0;
    height: 355px;
    svg {
      margin-top: 10px;
    }
  }
  &__table-data {
    font-size: var(--font-size-12) !important;
  }
  &__material-title-wrapper {
    display: flex;
  }
  &__material-title {
    background: var(--color-light-black);
    display: inline-block;
    color: white;
    padding: 10px 25px;
    font-size: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    border-radius: 0px 0px 34px 0px;
    text-transform: capitalize;
    font-weight: 600;
  }
  &__edge-thickness {
    margin-left: -56px;
    font-size: 16px;
    font-weight: 600;
  }
  &__center-thickness {
    font-size: 16px;
    font-weight: 600;
  }
  &__not-avaiable {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 30%;
  }
}

.material-compare-customized-dialog {
  &__close-icon {
    color: var(--color-black) !important;
    z-index: 3 !important;
    padding: 2px !important;
  }
  .MuiDialog-paper {
    width: 100% !important;
    height: 94% !important;
    max-width: 100% !important;
    max-height: 100% !important;
  }
  .MuiDialog-paper::-webkit-scrollbar {
    display: none !important;
  }
  .thickness-dialog-content {
    display: flex;
  }
  .css-1t4vnk2-MuiDialogContent-root {
    padding: 30px 40px !important;
  }
  .css-1t4vnk2-MuiDialogContent-root::-webkit-scrollbar {
    display: none !important;
  }
  .shared-slider-container{
    padding: 55px 0px 15px 0px !important;
  }
}
.material-thickness-Comparison .range-slider {
  text-align: center;
}

@media only screen and (max-width: 1050px) {
  .material-thickness-Comparison {
    left: 112px;

    .card-text {
      font-size: var(--font-size-12);
    }
    &__material-title {
      font-size: 12px;
    }
  }
}
@media only screen and (min-width: 1051px) and (max-width: 1300px) {
  .material-thickness-Comparison {
    &__material-title {
      font-size: 13px;
    }
  }
}
